// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatRoomList-module-title-aTUK6 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  font-family: "nexa";
  color: #eee;
  margin: 0;
}

.-ChatRoomList-module-subtitle-D91yQ {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #B3B3B3;
  margin: 0;
  padding: 0 16px;
}

.-ChatRoomList-module-chatList-rcVdQ {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 16px;
}
.-ChatRoomList-module-chatList-rcVdQ .-ChatRoomList-module-chatListItem-J0qN0 {
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background-color: #222;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.-ChatRoomList-module-chatList-rcVdQ .-ChatRoomList-module-chatListItem-J0qN0 .-ChatRoomList-module-name-VmH3i {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  color: #eee;
  margin: 0 0 12px 0;
}
.-ChatRoomList-module-chatList-rcVdQ .-ChatRoomList-module-chatListItem-J0qN0 .-ChatRoomList-module-description-FzgLq {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  color: #B3B3B3;
}
.-ChatRoomList-module-chatList-rcVdQ .-ChatRoomList-module-chatListItem-J0qN0 .-ChatRoomList-module-titles-hQeav {
  text-align: left;
}
.-ChatRoomList-module-chatList-rcVdQ .-ChatRoomList-module-chatListItem-J0qN0 .-ChatRoomList-module-date-GZJeS {
  font-family: "Barlow";
  text-align: right;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 0.875rem;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatRoomList/ChatRoomList.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WC0Ca;EDzCb,SAAA;AAFF;;AAKA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCkCe;EDjCf,SAAA;EACA,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;AAFF;AAGE;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,sBCUa;EDTb,aAAA;EACA,SAAA;EACA,sBAAA;AADJ;AAGI;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WCSS;EDRT,kBAAA;AADN;AAII;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,cAAA;AAFN;AAKI;EACE,gBAAA;AAHN;AAMI;EACE,qBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WCxCmB;ADoCzB","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `-ChatRoomList-module-title-aTUK6`,
	"subtitle": `-ChatRoomList-module-subtitle-D91yQ`,
	"chatList": `-ChatRoomList-module-chatList-rcVdQ`,
	"chatListItem": `-ChatRoomList-module-chatListItem-J0qN0`,
	"name": `-ChatRoomList-module-name-VmH3i`,
	"description": `-ChatRoomList-module-description-FzgLq`,
	"titles": `-ChatRoomList-module-titles-hQeav`,
	"date": `-ChatRoomList-module-date-GZJeS`
};
export default ___CSS_LOADER_EXPORT___;
