// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Avatar-module-wrapper-SPWLT {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,6BAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Avatar-module-wrapper-SPWLT`
};
export default ___CSS_LOADER_EXPORT___;
