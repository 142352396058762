// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FullModal-module-backdrop-KO0bG {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #111;
  z-index: 19;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.-FullModal-module-backdrop-KO0bG.-FullModal-module-open-zJJcm .-FullModal-module-modal-b0ATX {
  opacity: 1;
  top: 0;
}
.-FullModal-module-backdrop-KO0bG .-FullModal-module-modal-b0ATX {
  opacity: 0;
  transition: all ease 500ms;
  position: fixed;
  top: 200px;
  left: 0;
  width: 100%;
  background-color: #111;
  border-radius: 0px;
  z-index: 50;
  max-width: 512px;
  padding-bottom: env(safe-area-inset-bottom);
}
.-FullModal-module-backdrop-KO0bG .-FullModal-module-modal-b0ATX.-FullModal-module-chat-oU9wH {
  background-color: #111;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.-FullModal-module-backdrop-KO0bG .-FullModal-module-modal-b0ATX.-FullModal-module-large-dzkKR {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/FullModal/FullModal.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,sBC8Bc;ED7Bd,WAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AAFF;AAKI;EACE,UAAA;EACA,MAAA;AAHN;AAOE;EACE,UAAA;EACA,0BAAA;EACA,eAAA;EACA,UAAA;EACA,OAAA;EACA,WAAA;EACA,sBCOY;EDNZ,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,2CAAA;AALJ;AAOI;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AALN;AAQI;EACE,aAAA;AANN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `-FullModal-module-backdrop-KO0bG`,
	"open": `-FullModal-module-open-zJJcm`,
	"modal": `-FullModal-module-modal-b0ATX`,
	"chat": `-FullModal-module-chat-oU9wH`,
	"large": `-FullModal-module-large-dzkKR`
};
export default ___CSS_LOADER_EXPORT___;
