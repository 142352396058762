// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Toggle-module-wrapper-VSSEW {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN {
  display: none;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN + .-Toggle-module-toggle-TIOdE {
  height: auto;
  width: 64px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 20px;
  outline: 0;
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  background-color: #191919;
  border: 1px solid #484848;
  transition: background-color ease 100ms;
  /* remove blue tap color */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN + .-Toggle-module-toggle-TIOdE.-Toggle-module-pending-Q3qNi {
  background-color: #29A383;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN + .-Toggle-module-toggle-TIOdE::after {
  position: relative;
  left: 0;
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  background: #6E6E6E;
  transition: all 100ms ease;
  content: "";
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN:checked + .-Toggle-module-toggle-TIOdE {
  background-color: #29A383;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN:checked + .-Toggle-module-toggle-TIOdE.-Toggle-module-pending-Q3qNi {
  background-color: #29A383;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN:checked + .-Toggle-module-toggle-TIOdE::after {
  background: #ffffff;
}
.-Toggle-module-wrapper-VSSEW .-Toggle-module-checkbox-kWHJN:checked + .-Toggle-module-toggle-TIOdE::after {
  left: 23px;
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/Toggle.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAFF;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBCiBe;EDhBf,yBAAA;EACA,uCAAA;EACA,0BAAA;EACA,mDAAA;AAHJ;AAII;EACE,yBCwBS;AD1Bf;AAME;EACE,kBAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,mBCGQ;EDFR,0BAAA;EACA,WAAA;AAJJ;AAOE;EACE,yBCOW;ADZf;AAMI;EACE,yBCKS;ADTf;AAMI;EACE,mBC3CE;ADuCR;AAQE;EACE,UAAA;AANJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Toggle-module-wrapper-VSSEW`,
	"checkbox": `-Toggle-module-checkbox-kWHJN`,
	"toggle": `-Toggle-module-toggle-TIOdE`,
	"pending": `-Toggle-module-pending-Q3qNi`
};
export default ___CSS_LOADER_EXPORT___;
