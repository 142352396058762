
import { ChangeEvent, FC } from 'react';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './RadioGroup.module.scss';


export type RadioGroupItem = [
  key: string,
  description: string,
];

export interface RadioGroupProps {
  value: any;
  label: string;
  name: string;
  items?: RadioGroupItem[];
  errorText?: string;
  focused?: boolean;
  touched?: boolean;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  value,
  label,
  items,
  errorText,
  focused = false,
  touched = false,
  required = false,
  onChange,
}) => {
  const fakeRadioStyles = [
    styles.fakeRadio,
    errorText && styles.fakeRadioError,
  ].join(' ');

  return (
    <div className={styles.radioGroupWrapper}>
      <FieldLabel label={label} required={required} />
      <div className={styles.itemsWrapper}>
        {[...items].map((item) => (
          <div key={item[1]} className={styles.radioWrapper} role="radiogroup">
            <input
              id={`${name}${item[0]}`}
              className={styles.radio}
              type="radio"
              name={name}
              value={item[1]}
              checked={value === item[1].toString()}
              onChange={onChange}
            />
            <label
              htmlFor={`${name}${item[0]}`}
              className={fakeRadioStyles}
              role="radio"
              aria-checked={value === item[1].toString()}
            >
              <div className={styles.inner} />
            </label>
            <div className={styles.radioLabel}>{item[0]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
