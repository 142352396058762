// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FieldLabel-module-wrapper-bx9mm {
  margin-bottom: 4px;
  color: #eee;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
}
.-FieldLabel-module-wrapper-bx9mm span {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.-FieldLabel-module-wrapper-bx9mm.-FieldLabel-module-light-UPqyW {
  color: #ffffff;
}

.-FieldLabel-module-disabled-tvkGl {
  color: #cfd8dc;
}`, "",{"version":3,"sources":["webpack://./src/components/FieldLabel/FieldLabel.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WC+Ca;ED9Cb,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AADF;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;;AAIA;EACE,cCNM;ADKR;;AAIA;EACE,cCNS;ADKX","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-FieldLabel-module-wrapper-bx9mm`,
	"light": `-FieldLabel-module-light-UPqyW`,
	"disabled": `-FieldLabel-module-disabled-tvkGl`
};
export default ___CSS_LOADER_EXPORT___;
