// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-TextField-module-wrapper-L18ko {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.-TextField-module-wrapper-L18ko input, .-TextField-module-wrapper-L18ko textarea {
  display: flex;
  min-height: 56px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #606060;
  background: #2A2A2A;
  color: #B3B3B3;
  width: 100%;
  font-size: 17px;
  font-family: "Barlow", Helvetica, Arial, sans-serif;
}
.-TextField-module-wrapper-L18ko input:focus, .-TextField-module-wrapper-L18ko input:active, .-TextField-module-wrapper-L18ko textarea:focus, .-TextField-module-wrapper-L18ko textarea:active {
  background: #313131;
  outline: none;
  border: 1px solid #606060;
  color: #eee;
}
.-TextField-module-wrapper-L18ko input::-webkit-outer-spin-button, .-TextField-module-wrapper-L18ko input::-webkit-inner-spin-button, .-TextField-module-wrapper-L18ko textarea::-webkit-outer-spin-button, .-TextField-module-wrapper-L18ko textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.-TextField-module-wrapper-L18ko input[type=number], .-TextField-module-wrapper-L18ko textarea[type=number] {
  -moz-appearance: textfield;
}

.-TextField-module-charCount-_JDhI {
  color: #eee;
  font-size: 16px;
  line-height: 20px;
  padding: 4px;
}

.-TextField-module-wrapper-L18ko textarea {
  line-height: 24px;
}

.-TextField-module-wrapper-L18ko.-TextField-module-readOnly-Bzkt7 input, .-TextField-module-wrapper-L18ko.-TextField-module-readOnly-Bzkt7 textarea {
  background-color: #191919;
  color: #B3B3B3;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #B3B3B3;
  font-size: 17px;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #B3B3B3;
  font-size: 17px;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #B3B3B3;
  font-size: 17px;
}

:-moz-placeholder { /* Firefox 18- */
  color: #B3B3B3;
  font-size: 17px;
}

.-TextField-module-wrapperError-Yh5uF > ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #FF977D;
}

.-TextField-module-wrapperError-Yh5uF > ::-moz-placeholder { /* Firefox 19+ */
  color: #FF977D;
}

.-TextField-module-wrapperError-Yh5uF > :-ms-input-placeholder { /* IE 10+ */
  color: #FF977D;
}

.-TextField-module-wrapperError-Yh5uF > :-moz-placeholder { /* Firefox 18- */
  color: #FF977D;
}`, "",{"version":3,"sources":["webpack://./src/components/TextField/TextField.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,gBCQW;ADVb;;AAKA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBE0BgB;EFzBhB,cEiCe;EFhCf,WAAA;EACA,eAAA;EACA,mDAAA;AAFF;AAGE;EACE,mBEqBY;EFpBZ,aAAA;EACA,yBAAA;EACA,WEwBW;AFzBf;AAGE;EAEE,wBAAA;EACA,SAAA;AAFJ;AAKE;EACE,0BAAA;AAHJ;;AAMA;EACE,WEWa;EFVb,eAAA;EACA,iBAAA;EACA,YAAA;AAHF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE,yBEPiB;EFQjB,cECe;AFHjB;;AAKA,8BAAA,wBAAA;EACE,cEHe;EFIf,eAAA;AAFF;;AAIA,qBAAA,gBAAA;EACE,cEPe;EFQf,eAAA;AADF;;AAGA,yBAAA,WAAA;EACE,cEXe;EFYf,eAAA;AAAF;;AAEA,oBAAA,gBAAA;EACE,cEfe;EFgBf,eAAA;AACF;;AAGA,sEAAA,wBAAA;EACE,cEnBW;AFmBb;;AAEA,6DAAA,gBAAA;EACE,cEtBW;AFuBb;;AACA,iEAAA,WAAA;EACE,cEzBW;AF2Bb;;AAAA,4DAAA,gBAAA;EACE,cE5BW;AF+Bb","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-TextField-module-wrapper-L18ko`,
	"charCount": `-TextField-module-charCount-_JDhI`,
	"readOnly": `-TextField-module-readOnly-Bzkt7`,
	"wrapperError": `-TextField-module-wrapperError-Yh5uF`
};
export default ___CSS_LOADER_EXPORT___;
