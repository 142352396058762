// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UIListItem-module-wrapper-azjKf {
  padding: 12px;
  background-color: #191919;
  border-radius: 12px;
}
.-UIListItem-module-wrapper-azjKf.-UIListItem-module-activeButton-fO63M {
  background: #313131;
  border-radius: 12px 12px 0 0;
}
.-UIListItem-module-wrapper-azjKf .-UIListItem-module-listItem-P0aZB {
  display: flex;
  align-items: center;
  direction: ltr;
  color: #eee;
  gap: 4px;
}
.-UIListItem-module-wrapper-azjKf .-UIListItem-module-listItem-P0aZB .-UIListItem-module-main-L6ZSn {
  flex: 1;
}
.-UIListItem-module-wrapper-azjKf .-UIListItem-module-listItem-P0aZB .-UIListItem-module-main-L6ZSn .-UIListItem-module-title-f8rg_ {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.-UIListItem-module-wrapper-azjKf .-UIListItem-module-listItem-P0aZB .-UIListItem-module-main-L6ZSn .-UIListItem-module-content-Ad1DB {
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/UIListItem/UIListItem.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,yBCsCiB;EDrCjB,mBAAA;AAFF;AAGE;EACE,mBCqCY;EDpCZ,4BAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,WCoCW;EDnCX,QAAA;AADJ;AAGI;EACE,OAAA;AADN;AAEM;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AAEM;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAAR","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-UIListItem-module-wrapper-azjKf`,
	"activeButton": `-UIListItem-module-activeButton-fO63M`,
	"listItem": `-UIListItem-module-listItem-P0aZB`,
	"main": `-UIListItem-module-main-L6ZSn`,
	"title": `-UIListItem-module-title-f8rg_`,
	"content": `-UIListItem-module-content-Ad1DB`
};
export default ___CSS_LOADER_EXPORT___;
