// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-RadioGroup-module-itemsWrapper-mp_9m {
  display: flex;
  flex-direction: row;
}

.-RadioGroup-module-radioWrapper-uIxyp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
}

.-RadioGroup-module-radioWrapper-uIxyp:last-child {
  margin-left: 16px;
  margin-right: 0px;
}

.-RadioGroup-module-radioWrapper-uIxyp:first-child {
  margin-left: 0px;
  margin-right: 16px;
}

.-RadioGroup-module-radio-ySdhO {
  opacity: 0;
  width: 32px;
  height: 32px;
  margin: 0px;
}

.-RadioGroup-module-radio-ySdhO + .-RadioGroup-module-fakeRadio-EvJgO > .-RadioGroup-module-inner-ITPuC {
  height: 0px;
  width: 0px;
  border-radius: 50%;
  background-color: transparent;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.-RadioGroup-module-radio-ySdhO:checked + .-RadioGroup-module-fakeRadio-EvJgO > .-RadioGroup-module-inner-ITPuC {
  height: 22px;
  width: 22px;
  background-color: #fcdb00;
}

.-RadioGroup-module-radio-ySdhO:focus + .-RadioGroup-module-fakeRadio-EvJgO {
  border: 2px solid #fcdb00;
}

.-RadioGroup-module-fakeRadio-EvJgO {
  position: absolute;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  padding: 3px;
  border-radius: 50%;
  margin-bottom: 8px;
  border: 2px solid #78909c;
  background-color: transparent;
  color: #ffffff;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  /* remove blue tap color */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.-RadioGroup-module-fakeRadioError-Nx_8C {
  border: 2px solid #f1321e;
}

.-RadioGroup-module-radioLabel-kKD9I, .-RadioGroup-module-label-QPJMu {
  margin-top: 8px;
  font-size: 1rem;
  color: #78909c;
}`, "",{"version":3,"sources":["webpack://./src/components/RadioGroup/RadioGroup.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YCPO;ADIT;;AAMA;EACE,iBCXO;EDYP,iBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,kBCjBO;ADcT;;AAMA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AAHF;;AAMA;EACE,WAAA;EACA,UAAA;EACA,kBAAA;EACA,6BAAA;EACA,qDAAA;AAHF;;AAMA;EACE,YAAA;EACA,WAAA;EACA,yBE5CO;AFyCT;;AAMA;EACE,yBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,6BAAA;EACA,cElDM;EFmDN,iBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,qDAAA;EACA,0BAAA;EACA,mDAAA;AAHF;;AAMA;EACE,yBAAA;AAHF;;AAMA;EACE,eAAA;EACA,eAAA;EACA,cE5DS;AFyDX","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsWrapper": `-RadioGroup-module-itemsWrapper-mp_9m`,
	"radioWrapper": `-RadioGroup-module-radioWrapper-uIxyp`,
	"radio": `-RadioGroup-module-radio-ySdhO`,
	"fakeRadio": `-RadioGroup-module-fakeRadio-EvJgO`,
	"inner": `-RadioGroup-module-inner-ITPuC`,
	"fakeRadioError": `-RadioGroup-module-fakeRadioError-Nx_8C`,
	"radioLabel": `-RadioGroup-module-radioLabel-kKD9I`,
	"label": `-RadioGroup-module-label-QPJMu`
};
export default ___CSS_LOADER_EXPORT___;
