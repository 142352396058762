// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CommentForm-module-form-sukBx {
  width: 100%;
  padding: 12px 0;
  background: #111;
}
.-CommentForm-module-form-sukBx .-CommentForm-module-container-iDwkX {
  display: flex;
  align-items: flex-start;
}
.-CommentForm-module-form-sukBx .-CommentForm-module-container-iDwkX .-CommentForm-module-submit-ZgKO9 {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/forms/CommentForm/CommentForm.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,eAAA;EACA,gBCgCc;ADnChB;AAKE;EACE,aAAA;EACA,uBAAA;AAHJ;AAKI;EACE,aAAA;AAHN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `-CommentForm-module-form-sukBx`,
	"container": `-CommentForm-module-container-iDwkX`,
	"submit": `-CommentForm-module-submit-ZgKO9`
};
export default ___CSS_LOADER_EXPORT___;
