// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FieldStatusBlock-module-blockWrapper-RxNHb {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
}

.-FieldStatusBlock-module-statusBlock-TlZCl {
  flex: 1;
  margin-bottom: 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-light-lG7N8 {
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #ffffff;
}

.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-focused-Sq_BH {
  border-color: #fcdb00;
  margin-bottom: 0px;
  border-bottom-width: 2px;
}

.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A input, .-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A textarea {
  border-color: #FF977D;
  color: #FF977D;
}
.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #FF977D;
}
.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A ::-moz-placeholder {
  /* Firefox 19+ */
  color: #FF977D;
}
.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A :-ms-input-placeholder {
  /* IE 10+ */
  color: #FF977D;
}
.-FieldStatusBlock-module-statusBlock-TlZCl.-FieldStatusBlock-module-error-BcB3A :-moz-placeholder {
  /* Firefox 18- */
  color: #FF977D;
}

.-FieldStatusBlock-module-errorMessage-WRkPD {
  color: #FF977D;
  text-align: left;
}

@media only screen and (min-width: 1020px) {
  .-FieldStatusBlock-module-wrapper-BinUp {
    max-width: 600px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FieldStatusBlock/FieldStatusBlock.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;AAHF;;AAMA;EACE,OAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AAHF;;AAMA;EACE,iBAAA;EACA,wBAAA;EACA,qBCZM;ADSR;;AAOA;EACE,qBC5BO;ED6BP,kBAAA;EACA,wBAAA;AAJF;;AAQE;EACE,qBCmBS;EDlBT,cCkBS;ADvBb;AAQE;EACI,wBAAA;EACA,cCaO;ADnBb;AASI;EACE,gBAAA;EACA,cCQO;ADfb;AAUI;EACE,WAAA;EACA,cCGO;ADXb;AAWI;EACE,gBAAA;EACA,cCFO;ADPb;;AAaA;EACE,cCPW;EDQX,gBAAA;AAVF;;AAaA;EACE;IACE,gBErDS;EF2CX;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockWrapper": `-FieldStatusBlock-module-blockWrapper-RxNHb`,
	"statusBlock": `-FieldStatusBlock-module-statusBlock-TlZCl`,
	"light": `-FieldStatusBlock-module-light-lG7N8`,
	"focused": `-FieldStatusBlock-module-focused-Sq_BH`,
	"error": `-FieldStatusBlock-module-error-BcB3A`,
	"errorMessage": `-FieldStatusBlock-module-errorMessage-WRkPD`,
	"wrapper": `-FieldStatusBlock-module-wrapper-BinUp`
};
export default ___CSS_LOADER_EXPORT___;
