// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-MentionsEditorChat-module-editorWrapper-vlWNJ {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 60px);
}

.-MentionsEditorChat-module-editor-ZNB_V {
  box-sizing: border-box;
  cursor: text;
  background: #313131;
  font-family: sans-serif;
  line-height: 25px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #606060;
  padding: 4px 16px;
  font-size: 1rem;
  color: #eee;
  resize: none;
  outline: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  -webkit-appearance: none;
  max-width: 100%;
}
.-MentionsEditorChat-module-editor-ZNB_V ::placeholder {
  font-style: italic;
}
.-MentionsEditorChat-module-editor-ZNB_V textarea {
  overflow: "auto" !important;
}
.-MentionsEditorChat-module-editor-ZNB_V.-MentionsEditorChat-module-error-GHBj3 {
  border-color: #FF977D;
}
.-MentionsEditorChat-module-editor-ZNB_V .public-DraftStyleDefault-block {
  margin: 0;
}
.-MentionsEditorChat-module-editor-ZNB_V .DraftEditor-root {
  max-height: 200px;
  overflow-y: scroll;
}
.-MentionsEditorChat-module-editor-ZNB_V .-MentionsEditorChat-module-replyContainer-FAzKy {
  background-color: #191919;
  padding: 12px;
  border-radius: 12px;
  margin-top: 4px;
  width: auto;
}
.-MentionsEditorChat-module-editor-ZNB_V .-MentionsEditorChat-module-replyContainer-FAzKy .-MentionsEditorChat-module-author-mcrEw {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.-MentionsEditorChat-module-editor-ZNB_V .-MentionsEditorChat-module-replyContainer-FAzKy .-MentionsEditorChat-module-author-mcrEw .-MentionsEditorChat-module-closeIcon-Y_RtG {
  cursor: pointer;
  text-align: right;
}
.-MentionsEditorChat-module-editor-ZNB_V .-MentionsEditorChat-module-replyContainer-FAzKy .-MentionsEditorChat-module-content-zdgbI {
  padding: 0;
  margin: 0.5rem 0 0.5rem 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2;
  color: #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.-MentionsEditorChat-module-helperText-cEsHI {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #eee;
  padding: 8px 0 12px 0;
}

.-MentionsEditorChat-module-textAreaEditor-O7SIq textarea {
  overflow: "auto" !important;
  background: none;
  border: none;
  width: 100%;
  background: #313131;
  font-family: sans-serif;
  line-height: 25px;
  position: relative;
  font-size: 1rem;
  color: #eee;
  resize: none;
  outline: none;
}
.-MentionsEditorChat-module-textAreaEditor-O7SIq textarea::placeholder {
  font-style: italic;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/MentionsEditor/MentionsEditorChat.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,OAAA;EACA,4BAAA;AAFF;;AAKA;EACE,sBAAA;EACA,YAAA;EACA,mBCgCc;ED/Bd,uBAAA;EACA,iBAAA;EACA,kBAAA;EAEA,kBEbY;EFcZ,yBAAA;EACA,iBAAA;EACA,eAAA;EAGA,WC2Ba;ED1Bb,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,OAAA;EACA,aAAA;EACA,wBAAA;EACA,eAAA;AALF;AAOE;EACE,kBAAA;AALJ;AAQE;EACE,2BAAA;AANJ;AASE;EACE,qBCWS;ADlBb;AAUE;EACE,SAAA;AARJ;AAWE;EACE,iBAAA;EACA,kBAAA;AATJ;AAYE;EACE,yBCbe;EDcf,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AAVJ;AAaI;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cCzDE;ED0DF,aAAA;EACA,8BAAA;AAXN;AAaM;EACE,eAAA;EACA,iBAAA;AAXR;AAeI;EACE,UAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,WCjCS;EDkCT,mBAAA;EACA,gBAAA;EACA,uBAAA;AAbN;;AAmBA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WC/Ca;EDgDb,qBAAA;AAhBF;;AAoBE;EACE,2BAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,mBC/DY;EDgEZ,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,WC9DW;ED+DX,YAAA;EACA,aAAA;AAjBJ;AAmBI;EACE,kBAAA;EACA,eAAA;AAjBN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorWrapper": `-MentionsEditorChat-module-editorWrapper-vlWNJ`,
	"editor": `-MentionsEditorChat-module-editor-ZNB_V`,
	"error": `-MentionsEditorChat-module-error-GHBj3`,
	"replyContainer": `-MentionsEditorChat-module-replyContainer-FAzKy`,
	"author": `-MentionsEditorChat-module-author-mcrEw`,
	"closeIcon": `-MentionsEditorChat-module-closeIcon-Y_RtG`,
	"content": `-MentionsEditorChat-module-content-zdgbI`,
	"helperText": `-MentionsEditorChat-module-helperText-cEsHI`,
	"textAreaEditor": `-MentionsEditorChat-module-textAreaEditor-O7SIq`
};
export default ___CSS_LOADER_EXPORT___;
