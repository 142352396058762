// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Loader-module-wrapper-J1gds {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.-Loader-module-wrapper-J1gds.-Loader-module-fullPage-cOGLu {
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  background: #111;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
}
.-Loader-module-wrapper-J1gds.-Loader-module-fullPage-cOGLu .-Loader-module-loaderWrapper-_eXjF {
  margin-top: 20vh;
}
.-Loader-module-wrapper-J1gds .-Loader-module-loaderWrapper-_eXjF {
  width: 94px;
  text-align: center;
}
.-Loader-module-wrapper-J1gds .-Loader-module-loaderWrapper-_eXjF .-Loader-module-loader-nEUls {
  width: 16px;
  height: 16px;
  margin: 4px;
  border-radius: 100%;
  display: inline-block;
  background-color: #FFD700;
  -webkit-animation: -Loader-module-skbouncedelay-uwhuj 1.4s infinite ease-in-out both;
  animation: -Loader-module-skbouncedelay-uwhuj 1.4s infinite ease-in-out both;
}
.-Loader-module-wrapper-J1gds .-Loader-module-loaderWrapper-_eXjF .-Loader-module-loader1-CF9mg {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.-Loader-module-wrapper-J1gds .-Loader-module-loaderWrapper-_eXjF .-Loader-module-loader2-rTq6x {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.-Loader-module-wrapper-J1gds .-Loader-module-label-01JAi {
  margin: 7px;
  color: #B3B3B3;
}
.-Loader-module-wrapper-J1gds .-Loader-module-title-gi9Xb {
  font-family: "nexa";
  color: #eee;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 7px;
}

@-webkit-keyframes -Loader-module-skbouncedelay-uwhuj {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes -Loader-module-skbouncedelay-uwhuj {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,aCEY;EDDZ,aAAA;EACA,sBAAA;EACA,mBAAA;AAHF;AAKE;EACE,YAAA;EACA,aAAA;EACA,2BAAA;EACA,gBEyBY;EFxBZ,SAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;AAHJ;AAII;EACE,gBAAA;AAFN;AAME;EACE,WAAA;EACA,kBAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBEMa;EFLb,oFAAA;EACA,4EAAA;AAJN;AAOI;EACE,+BAAA;EACA,uBAAA;AALN;AAQI;EACE,+BAAA;EACA,uBAAA;AANN;AAUE;EACE,WAAA;EACA,cAAA;AARJ;AAWE;EACE,mBAAA;EACA,WENW;EFOX,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AATJ;;AAaA;EAEE;IAGE,2BAAA;EAbF;EAgBA;IACE,2BAAA;EAdF;AACF;AAiBA;EAEE;IAGE,2BAAA;IACA,mBAAA;EAlBF;EAqBA;IACE,2BAAA;IACA,mBAAA;EAnBF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Loader-module-wrapper-J1gds`,
	"fullPage": `-Loader-module-fullPage-cOGLu`,
	"loaderWrapper": `-Loader-module-loaderWrapper-_eXjF`,
	"loader": `-Loader-module-loader-nEUls`,
	"skbouncedelay": `-Loader-module-skbouncedelay-uwhuj`,
	"loader1": `-Loader-module-loader1-CF9mg`,
	"loader2": `-Loader-module-loader2-rTq6x`,
	"label": `-Loader-module-label-01JAi`,
	"title": `-Loader-module-title-gi9Xb`
};
export default ___CSS_LOADER_EXPORT___;
