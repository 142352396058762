// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CheckboxButton-module-wrapper-zSmBC {
  display: inline-block;
}
.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-checkbox-Wo0Fc {
  display: none;
}
.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4 {
  position: relative;
  display: inline-block;
  padding: 8px;
  border-radius: 12px;
  margin: 4px;
  background-color: #191919;
  color: #B3B3B3;
  font-size: 1rem;
  transition: all 0.25s ease;
  box-sizing: border-box;
  font-weight: bold;
}
.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-active-GuvRD {
  background-color: #FFD700;
  color: #000000;
}
.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-disabled-usa6W {
  background-color: transparent;
}
.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-disabled-usa6W.-CheckboxButton-module-active-GuvRD {
  background-color: #2A2A2A;
  color: #eee;
}`, "",{"version":3,"sources":["webpack://./src/components/CheckboxButton/CheckboxButton.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,qBAAA;AAHF;AAIE;EACE,aAAA;AAFJ;AAIE;EACE,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,yBC4Be;ED3Bf,cCoCa;EDnCb,eAAA;EACA,0BAAA;EACA,sBAAA;EACA,iBAAA;AAFJ;AAII;EACE,yBCiBa;EDhBb,cCfE;ADaR;AAKI;EACE,6BAAA;AAHN;AAIM;EACE,yBCcU;EDbV,WCoBO;ADtBf","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CheckboxButton-module-wrapper-zSmBC`,
	"checkbox": `-CheckboxButton-module-checkbox-Wo0Fc`,
	"button": `-CheckboxButton-module-button-yCQz4`,
	"active": `-CheckboxButton-module-active-GuvRD`,
	"disabled": `-CheckboxButton-module-disabled-usa6W`
};
export default ___CSS_LOADER_EXPORT___;
