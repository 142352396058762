// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ButtonGroup-module-buttonGroup-csqOB {
  max-width: 335px;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.-ButtonGroup-module-wrapper-Tzpy6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: center;
}
.-ButtonGroup-module-wrapper-Tzpy6.-ButtonGroup-module-borderTop-VB8i2 {
  width: 100%;
  border-top: 1px solid #3a3a3a;
  margin-bottom: env(safe-area-inset-bottom);
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonGroup/ButtonGroup.module.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,QCFY;EDGZ,sBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aCTO;EDUP,mBAAA;EACA,uBAAA;AAFF;AAGE;EACE,WAAA;EACA,6BAAA;EACA,0CAAA;AADJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": `-ButtonGroup-module-buttonGroup-csqOB`,
	"wrapper": `-ButtonGroup-module-wrapper-Tzpy6`,
	"borderTop": `-ButtonGroup-module-borderTop-VB8i2`
};
export default ___CSS_LOADER_EXPORT___;
