// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-TextFieldSlim-module-wrapper-fQ7Tj {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 0px 8px 0px;
  border-width: 0px 0px 1px 0px;
  border-radius: 0;
  border-style: solid;
}

.-TextFieldSlim-module-label-KWivf {
  position: absolute;
  top: 30px;
  left: 0px;
  text-align: left;
  pointer-events: none;
  transition: all 0.15s ease;
  font-size: 18px;
  font-weight: 400;
  color: grey;
  opacity: 1;
  transition: all 0.25s ease;
}

.-TextFieldSlim-module-wrapper-fQ7Tj > input, .-TextFieldSlim-module-wrapper-fQ7Tj > textarea {
  padding: 0;
  line-height: 32px;
  min-height: 34px;
  top: 8px;
  display: block;
  position: relative;
  box-sizing: padding-box;
  background-color: transparent;
  border: none;
  overflow: hidden;
  width: 100%;
  font-size: 18px;
  outline: none;
}

.-TextFieldSlim-module-wrapper-fQ7Tj > textarea {
  line-height: 24px;
}

.-TextFieldSlim-module-wrapperValue-LTkDu > .-TextFieldSlim-module-label-KWivf {
  opacity: 0;
  left: 25px;
}

.-TextFieldSlim-module-errorText-hEABd {
  color: #f1321e;
  position: absolute;
  bottom: -24px;
  right: 0px;
  pointer-events: none;
  font-weight: 400;
  line-height: 18px;
  animation-duration: 0.35s;
  animation-fill-mode: both;
}`, "",{"version":3,"sources":["webpack://./src/components/TextFieldSlim/TextFieldSlim.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,gBAAA;EACA,oBAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,0BAAA;AAFF;;AAKA;EACE,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,QC1BY;ED2BZ,cAAA;EACA,kBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;AAFF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE,UAAA;EACA,UAAA;AAFF;;AAKA;EACE,cEhDI;EFiDJ,kBAAA;EACA,aAAA;EACA,UAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;AAFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-TextFieldSlim-module-wrapper-fQ7Tj`,
	"label": `-TextFieldSlim-module-label-KWivf`,
	"wrapperValue": `-TextFieldSlim-module-wrapperValue-LTkDu`,
	"errorText": `-TextFieldSlim-module-errorText-hEABd`
};
export default ___CSS_LOADER_EXPORT___;
