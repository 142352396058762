// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-AutoTranslate-module-main-IdPCb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-content-vgqJZ {
  flex: 1;
}

.-AutoTranslate-module-translateWrapper-EDNmW {
  background: #222;
}

.-AutoTranslate-module-translateContent-DMxip {
  font-weight: 400;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #B3B3B3;
  padding: 4px;
}
.-AutoTranslate-module-translateContent-DMxip p {
  margin: 0;
}

@supports (display: grid) {
  .-AutoTranslate-module-main-IdPCb {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "content toggle";
    grid-column-gap: 8px;
  }
  .-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-content-vgqJZ {
    grid-template-areas: "content";
  }
  .-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-toggle-bxwhK {
    grid-template-areas: "toggle";
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AutoTranslate.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAFF;AAIE;EACE,OAAA;AAFJ;;AAMA;EACE,gBC4Be;AD/BjB;;AAMA;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cC6Be;ED5Bf,YAAA;AAHF;AAIE;EACE,SAAA;AAFJ;;AAMA;EACE;IACE,aAAA;IACA,+BAAA;IACA,qCAAA;IACA,oBE9BU;EF2BZ;EAKE;IACE,8BAAA;EAHJ;EAME;IACE,6BAAA;EAJJ;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `-AutoTranslate-module-main-IdPCb`,
	"content": `-AutoTranslate-module-content-vgqJZ`,
	"translateWrapper": `-AutoTranslate-module-translateWrapper-EDNmW`,
	"translateContent": `-AutoTranslate-module-translateContent-DMxip`,
	"toggle": `-AutoTranslate-module-toggle-bxwhK`
};
export default ___CSS_LOADER_EXPORT___;
