// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ButtonIcon-module-wrap-Pdk8Y {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}
.-ButtonIcon-module-wrap-Pdk8Y.-ButtonIcon-module-fullWidth-cNfnJ {
  width: 100%;
}
.-ButtonIcon-module-wrap-Pdk8Y.-ButtonIcon-module-fullWidth-cNfnJ.-ButtonIcon-module-hasLabel-NnvPH img,
.-ButtonIcon-module-wrap-Pdk8Y.-ButtonIcon-module-fullWidth-cNfnJ.-ButtonIcon-module-hasLabel-NnvPH svg {
  position: absolute;
  top: 20px;
  left: 16px;
}

.-ButtonIcon-module-button-h1NaQ {
  display: flex;
  align-items: center;
  border: none;
  padding: 20px 16px;
  font-size: 1.4rem;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
.-ButtonIcon-module-button-h1NaQ.-ButtonIcon-module-fullWidth-cNfnJ {
  width: 100%;
}
.-ButtonIcon-module-button-h1NaQ .-ButtonIcon-module-label-iQQmX {
  color: inherit;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  flex: 1;
  padding-left: 4px;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonIcon/ButtonIcon.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAFF;AAGE;EACE,WAAA;AADJ;AAIQ;;EAEE,kBAAA;EACA,SAAA;EACA,UAAA;AAFV;;AAQA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;AALF;AAME;EACE,WAAA;AAJJ;AAOE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,OAAA;EACA,iBAAA;EACA,uBAAA;AALJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `-ButtonIcon-module-wrap-Pdk8Y`,
	"fullWidth": `-ButtonIcon-module-fullWidth-cNfnJ`,
	"hasLabel": `-ButtonIcon-module-hasLabel-NnvPH`,
	"button": `-ButtonIcon-module-button-h1NaQ`,
	"label": `-ButtonIcon-module-label-iQQmX`
};
export default ___CSS_LOADER_EXPORT___;
