// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Footer-module-wrapper-OXEah {
  padding: 16px;
  background-color: #222;
  color: #ffffff;
}
.-Footer-module-wrapper-OXEah .-Footer-module-topContent-ZIPZf {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  direction: ltr;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-topContent-ZIPZf .-Footer-module-logo-d3UqU {
  width: 84px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-topContent-ZIPZf .-Footer-module-moto-SC5bs {
  flex: 1;
  color: #FFD700;
  text-align: right;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.-Footer-module-wrapper-OXEah .-Footer-module-middleContent-VkIf9 {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 32px auto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-middleContent-VkIf9 a {
  display: inline-block;
  margin: 0 8px;
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-languageSelect-rK9QK {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 16px;
  color: #eee;
}
.-Footer-module-wrapper-OXEah .-Footer-module-storeLinks-d1o2P {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  gap: 16px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  color: #B3B3B3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomLeft-Q0yYD {
  text-align: left;
  width: 80%;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomRight-h9LRa {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomRight-h9LRa a {
  color: #FFD700;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 129.412% */
  text-decoration-line: underline;
  cursor: pointer;
}
.-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomRight-h9LRa span {
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomLeft-Q0yYD {
    width: 100%;
  }
  .-Footer-module-wrapper-OXEah .-Footer-module-bottomContent-NQ11S .-Footer-module-bottomRight-h9LRa {
    text-align: center;
    padding: 10px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,aCEO;EDDP,sBEqCe;EFpCf,cEKM;AFPR;AAIE;ECSA,gBAAA;EACA,iBAAA;EACA,kBAAA;EDTE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBCPK;ADOT;AAEI;EACE,WAAA;AAAN;AAGI;EACE,OAAA;EACA,cEmBa;EFlBb,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AADN;AAKE;ECdA,gBAAA;EACA,iBAAA;EACA,kBAAA;EDcE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAGI;EACE,qBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AADN;AAKE;ECnCA,gBAAA;EACA,iBAAA;EACA,kBAAA;EDmCE,kBAAA;EACA,mBCjDK;EDkDL,WELW;AFIf;AAIE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;AAFJ;AAKE;EClDA,gBAAA;EACA,iBAAA;EACA,kBAAA;EDkDE,cEjBa;EFkBb,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAGI;EACE,gBAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADN;AAGI;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AADN;AAGM;EACE,cEjDW;EFkDX,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,+BAAA;EACA,eAAA;AADR;AAGM;EACE,eAAA;AADR;AAII;EAnCF;IAoCI,8BAAA;IACA,uBAAA;EADJ;EAGI;IACE,WAAA;EADN;EAGI;IACE,kBAAA;IACA,eAAA;EADN;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Footer-module-wrapper-OXEah`,
	"topContent": `-Footer-module-topContent-ZIPZf`,
	"logo": `-Footer-module-logo-d3UqU`,
	"moto": `-Footer-module-moto-SC5bs`,
	"middleContent": `-Footer-module-middleContent-VkIf9`,
	"languageSelect": `-Footer-module-languageSelect-rK9QK`,
	"storeLinks": `-Footer-module-storeLinks-d1o2P`,
	"bottomContent": `-Footer-module-bottomContent-NQ11S`,
	"bottomLeft": `-Footer-module-bottomLeft-Q0yYD`,
	"bottomRight": `-Footer-module-bottomRight-h9LRa`
};
export default ___CSS_LOADER_EXPORT___;
