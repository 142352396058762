// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatMessage-module-wrapper-WlkRW {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  gap: 8px;
  margin: 0 auto;
  max-width: 512px;
  overflow-wrap: break-word;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-avatar-KwfUF {
  order: 1;
  height: 48px;
  width: 48px;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-messageFooter-FJEJ5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-messageFooter-FJEJ5 .-ChatMessage-module-likeButton-jmv6q {
  padding: 0;
  color: #ffffff;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-messageFooter-FJEJ5 .-ChatMessage-module-likeButton-jmv6q.-ChatMessage-module-liked-oocDq svg {
  color: #E54D2E;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 {
  order: 2;
  width: 85%;
  background-color: #222;
  border-radius: 12px;
  position: relative;
  padding: 8px;
  border: 1px solid #000000;
  color: #ffffff;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-author-gTXhg {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-content-eV80S {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 8px 0;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-time-j001C {
  color: #eee;
  margin: 0;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 400;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-like-w6DLm {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-iconNew-S3rp2 {
  margin: 2px;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: flex-start;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-icon-Z2fuu {
  margin: 0px;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: flex-end;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-replyContainer-vaQm6 {
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 8px;
  color: #eee;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-replyContainer-vaQm6 .-ChatMessage-module-author-gTXhg {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-replyContainer-vaQm6 .-ChatMessage-module-content-eV80S {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-toReply-fVCyh {
  background-color: #191919;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-fromReply-Mw6GZ {
  background-color: #F1CE36;
  color: #111;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-fromReply-Mw6GZ .-ChatMessage-module-author-gTXhg {
  font-size: 16px;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-body-cbRP0.-ChatMessage-module-quickView-Mc7M7 {
  background-color: #b0bec5;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-translatedMessage-uvLWu {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}
.-ChatMessage-module-wrapper-WlkRW .-ChatMessage-module-translationError-UAq1g {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  color: #FF977D;
}

.-ChatMessage-module-mention-RrWPy {
  font-weight: bold;
}

.-ChatMessage-module-likeNew-U_q7G {
  position: absolute;
  bottom: 0.4rem;
  left: 0.85rem;
}

.-ChatMessage-module-like-w6DLm {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.-ChatMessage-module-icon-Z2fuu {
  margin: 0px;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: flex-end;
}

.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-avatar-KwfUF {
  order: 2;
  color: #000000;
}
.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-body-cbRP0 {
  order: 1;
  background-color: #FFD700;
  border-radius: 8px;
  color: #000000;
}
.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-time-j001C {
  color: #000000;
}
.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-messageFooter-FJEJ5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-messageFooter-FJEJ5 .-ChatMessage-module-likeButton-jmv6q {
  padding: 0;
  color: #000000;
}
.-ChatMessage-module-fromUser-oHV_v .-ChatMessage-module-replyContainer-vaQm6 {
  background-color: #F1CE36;
  color: #111;
}

.-ChatMessage-module-blocked-oRYCp .-ChatMessage-module-avatar-KwfUF {
  opacity: 0.5;
}
.-ChatMessage-module-blocked-oRYCp .-ChatMessage-module-body-cbRP0 {
  color: #b0bec5;
  opacity: 0.5;
}
.-ChatMessage-module-blocked-oRYCp .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-author-gTXhg {
  color: #546e7a;
}
.-ChatMessage-module-blocked-oRYCp .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-content-eV80S {
  color: #546e7a;
}
.-ChatMessage-module-blocked-oRYCp .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-created-NQykR {
  color: #546e7a;
}

.-ChatMessage-module-hidden-aEnlV .-ChatMessage-module-avatar-KwfUF {
  opacity: 0.5;
}
.-ChatMessage-module-hidden-aEnlV .-ChatMessage-module-body-cbRP0 {
  color: #b0bec5;
  opacity: 0.5;
}
.-ChatMessage-module-hidden-aEnlV .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-author-gTXhg {
  color: #546e7a;
}
.-ChatMessage-module-hidden-aEnlV .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-content-eV80S {
  color: #546e7a;
}
.-ChatMessage-module-hidden-aEnlV .-ChatMessage-module-body-cbRP0 .-ChatMessage-module-created-NQykR {
  color: #546e7a;
}

.-ChatMessage-module-unBlockWrapper-Em1zC {
  margin-top: -12px;
}
.-ChatMessage-module-unBlockWrapper-Em1zC .-ChatMessage-module-unblockUser-Ch4KJ {
  background: #313131;
  border-radius: 0 0 12px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatMessage/ChatMessage.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,QAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;AAFF;AAIE;EACE,QAAA;EACA,YAAA;EACA,WAAA;AAFJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAFJ;AAGI;EACE,UAAA;EACA,cCbE;ADYR;AAGQ;EACE,cC4BQ;AD7BlB;AAME;EACE,QAAA;EACA,UAAA;EACA,sBCOa;EDNb,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,cC7BI;ADyBR;AAMI;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAJN;AAOI;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AALN;AAQI;EACE,WCPS;EDQT,SAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AANN;AASI;EACE,kBAAA;EACA,YAAA;EACA,cAAA;AAPN;AAUI;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,2BAAA;AARN;AAWI;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AATN;AAYI;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WCxCS;AD8Bf;AAYM;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAVR;AAYM;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AAVR;AAcI;EACE,yBCjEa;ADqDnB;AAeI;EACE,yBCvEkB;EDwElB,WC1EU;AD6DhB;AAcM;EACE,eAAA;AAZR;AAiBE;EACE,yBCzGO;AD0FX;AAkBE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AAhBJ;AAkBE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,cCjFS;ADiEb;;AAoBA;EACE,iBAAA;AAjBF;;AAoBA;EACE,kBAAA;EACA,cAAA;EACA,aAAA;AAjBF;;AAoBA;EACE,kBAAA;EACA,YAAA;EACA,cAAA;AAjBF;;AAoBA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AAjBF;;AAsBE;EACE,QAAA;EACA,cC9JI;AD2IR;AAsBE;EACE,QAAA;EACA,yBCpIe;EDqIf,kBExKU;EFyKV,cCrKI;ADiJR;AAqBI;EACE,cCvKE;ADoJR;AAqBQ;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAnBV;AAqBU;EACE,UAAA;EACA,cChLJ;AD6JR;AAuBE;EACE,yBCrJoB;EDsJpB,WCxJY;ADmIhB;;AA0BE;EACE,YAAA;AAvBJ;AA0BE;EACE,cCzLO;ED0LP,YAAA;AAxBJ;AA0BI;EACE,cCzLK;ADiKX;AA2BI;EACE,cC7LK;ADoKX;AA4BI;EACE,cCjMK;ADuKX;;AAgCE;EACE,YAAA;AA7BJ;AAgCE;EACE,cChNO;EDiNP,YAAA;AA9BJ;AAgCI;EACE,cChNK;ADkLX;AAiCI;EACE,cCpNK;ADqLX;AAkCI;EACE,cCxNK;ADwLX;;AAoCA;EACE,iBAAA;AAjCF;AAkCE;EACE,mBCtMY;EDuMZ,4BAAA;AAhCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-ChatMessage-module-wrapper-WlkRW`,
	"avatar": `-ChatMessage-module-avatar-KwfUF`,
	"messageFooter": `-ChatMessage-module-messageFooter-FJEJ5`,
	"likeButton": `-ChatMessage-module-likeButton-jmv6q`,
	"liked": `-ChatMessage-module-liked-oocDq`,
	"body": `-ChatMessage-module-body-cbRP0`,
	"author": `-ChatMessage-module-author-gTXhg`,
	"content": `-ChatMessage-module-content-eV80S`,
	"time": `-ChatMessage-module-time-j001C`,
	"like": `-ChatMessage-module-like-w6DLm`,
	"iconNew": `-ChatMessage-module-iconNew-S3rp2`,
	"icon": `-ChatMessage-module-icon-Z2fuu`,
	"replyContainer": `-ChatMessage-module-replyContainer-vaQm6`,
	"toReply": `-ChatMessage-module-toReply-fVCyh`,
	"fromReply": `-ChatMessage-module-fromReply-Mw6GZ`,
	"quickView": `-ChatMessage-module-quickView-Mc7M7`,
	"translatedMessage": `-ChatMessage-module-translatedMessage-uvLWu`,
	"translationError": `-ChatMessage-module-translationError-UAq1g`,
	"mention": `-ChatMessage-module-mention-RrWPy`,
	"likeNew": `-ChatMessage-module-likeNew-U_q7G`,
	"fromUser": `-ChatMessage-module-fromUser-oHV_v`,
	"blocked": `-ChatMessage-module-blocked-oRYCp`,
	"created": `-ChatMessage-module-created-NQykR`,
	"hidden": `-ChatMessage-module-hidden-aEnlV`,
	"unBlockWrapper": `-ChatMessage-module-unBlockWrapper-Em1zC`,
	"unblockUser": `-ChatMessage-module-unblockUser-Ch4KJ`
};
export default ___CSS_LOADER_EXPORT___;
