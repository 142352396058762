// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CommentField-module-wrapper-Of0yK {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 8px 0px;
}

.-CommentField-module-wrapper-Of0yK textarea {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eceff1;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: transparent;
  color: #37474f;
  resize: none;
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
}

.-CommentField-module-wrapper-Of0yK textarea.-CommentField-module-disabled-OYYTU {
  opacity: 0.5;
  color: #37474f;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #cfd8dc;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #cfd8dc;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #cfd8dc;
}

:-moz-placeholder { /* Firefox 18- */
  color: #cfd8dc;
}

.-CommentField-module-uppercase-j_PmQ {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/CommentField/CommentField.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;AAFF;;AAKA;EACE,kBAAA;EACA,cAAA;EACA,WAAA;EACA,kBCTY;EDUZ,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,6BAAA;EACA,cEGS;EFFT,YAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;AAFF;;AAKA;EACE,YAAA;EACA,cENS;AFIX;;AAKA,8BAAA,wBAAA;EACE,cEjBS;AFeX;;AAIA,qBAAA,gBAAA;EACE,cEpBS;AFmBX;;AAGA,yBAAA,WAAA;EACE,cEvBS;AFuBX;;AAEA,oBAAA,gBAAA;EACE,cE1BS;AF2BX;;AAEA;EACE,yBAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CommentField-module-wrapper-Of0yK`,
	"disabled": `-CommentField-module-disabled-OYYTU`,
	"uppercase": `-CommentField-module-uppercase-j_PmQ`
};
export default ___CSS_LOADER_EXPORT___;
