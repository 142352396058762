// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ActionButtonGroup-modules-actionButtonGroup-yuxNF {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #cfd8dc;
  margin-top: 16px;
}
.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button {
  background-color: transparent;
  box-shadow: none;
  flex: 1;
  padding: 16px;
  border: none;
  border-right: 1px solid #cfd8dc;
  font-size: 14px;
}
.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:last-child {
  border-right: none;
}
.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/ActionButtonGroup/ActionButtonGroup.modules.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBCDO;ADDT;AAIE;EACE,6BAAA;EACA,gBAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;EACA,+BAAA;EACA,eAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAKI;EAAU,oCAAA;AAFd","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtonGroup": `-ActionButtonGroup-modules-actionButtonGroup-yuxNF`
};
export default ___CSS_LOADER_EXPORT___;
