// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ActionSheet-module-backdrop-Sqdbu {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column-reverse;
  padding: 16px;
  background-color: rgba(17, 17, 17, 0.5);
  z-index: 250;
  font-weight: 700;
}
.-ActionSheet-module-backdrop-Sqdbu .-ActionSheet-module-modal-rk_Ng {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  background: #111;
}
.-ActionSheet-module-backdrop-Sqdbu .-ActionSheet-module-modal-rk_Ng .-ActionSheet-module-sheet-W1Rl7 {
  border-radius: 8px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/ActionSheet/ActionSheet.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;EACA,uCCmCc;EDlCd,YAAA;EACA,gBAAA;AAFF;AAIE;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,2CAAA;EACA,gDAAA;EACA,gBCgBY;ADlBhB;AAGI;EACE,kBEpBQ;EFqBR,gBAAA;AADN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `-ActionSheet-module-backdrop-Sqdbu`,
	"modal": `-ActionSheet-module-modal-rk_Ng`,
	"sheet": `-ActionSheet-module-sheet-W1Rl7`
};
export default ___CSS_LOADER_EXPORT___;
