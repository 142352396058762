// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
  flex: 1;
  display: flex;
  color: #eee;
  position: relative;
  font-size: 1rem;
  line-height: 25px;
}
.editor-container > div:first-child {
  width: 100%;
  outline: none;
  overflow: auto;
  max-height: 125px;
}
.editor-container .editor-placerholder {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  line-height: 25px;
}
.editor-container p {
  margin: 0;
}
.editor-container .mention {
  background-color: #6E6E6E !important;
  color: #eee !important;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.mentions-menu {
  position: absolute;
  min-width: 200px;
  max-width: 300px;
  z-index: 999;
  min-height: 40px;
  background: #222;
  padding: 12px;
  border-radius: 8px;
  bottom: 55px;
  border: 1px solid #606060;
  color: #eee;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
}
.mentions-menu .item {
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.mentions-menu .item img {
  width: 32px;
  height: 32px;
  max-width: 32px;
}
.mentions-menu .item span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/MentionsEditor/Editor.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,OAAA;EACA,aAAA;EACA,WC6Ca;ED5Cb,kBAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAIE;EACE,WAAA;EACA,aAAA;EACA,cAAA;EACA,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,SAAA;AAJJ;AAOE;EACE,oCAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AALJ;;AAUA;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBCRe;EDSf,aAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,WCJa;EDKb,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAPF;AAQE;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;AANJ;AAOI;EACE,WAAA;EACA,YAAA;EACA,eAAA;AALN;AAOI;EACE,OAAA;EACA,gBAAA;EACA,uBAAA;AALN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
