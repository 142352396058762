// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-TabNav-module-wrapper-ZLGQU {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #191919;
  color: #eee;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  z-index: 11;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 {
  display: flex;
  justify-content: space-around;
  padding: 2px 16px;
  margin: 0px;
  list-style: none;
  width: 100%;
  max-width: 960px;
  padding: 16px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 {
    padding: 12px;
  }
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li {
  display: inline-block;
  padding: 0px 16px;
  flex: 1;
  max-width: 25%;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #eee;
  max-width: 100%;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li a .-TabNav-module-badge-m0EOI {
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: 15px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #f1321e;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li a.-TabNav-module-active-Se7oZ {
  color: #FFD700;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li a.-TabNav-module-active-Se7oZ button {
  color: #FFD700;
}
.-TabNav-module-wrapper-ZLGQU ul.-TabNav-module-menu-MELj9 li a .-TabNav-module-label-IlGML {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/TabNav/TabNav.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,yBCoCiB;EDnCjB,WC2Ca;ED1Cb,2CAAA;EACA,gDAAA;EACA,WAAA;AAFF;AAIE;EACE,aAAA;EACA,6BAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,cAAA;AAFJ;AAGI;EAVF;IAWI,aAAA;EAAJ;AACF;AAEI;EACE,qBAAA;EACA,iBAAA;EACA,OAAA;EACA,cAAA;AAAN;AAEM;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,WCYO;EDXP,eAAA;AAAR;AAEQ;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBC7CJ;ED8CI,kBAAA;EACA,gBAAA;EACA,kBAAA;AAAV;AAGQ;EACE,cCjBS;ADgBnB;AAEU;EACE,cCnBO;ADmBnB;AAIQ;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAFV","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-TabNav-module-wrapper-ZLGQU`,
	"menu": `-TabNav-module-menu-MELj9`,
	"badge": `-TabNav-module-badge-m0EOI`,
	"active": `-TabNav-module-active-Se7oZ`,
	"label": `-TabNav-module-label-IlGML`
};
export default ___CSS_LOADER_EXPORT___;
