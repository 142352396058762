import {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLProps,
  useRef,
  useState,
} from 'react';

import styles from './CommentField.module.scss';
import { useTranslation } from 'react-i18next';

export interface CommentFieldProps extends HTMLProps<HTMLTextAreaElement> {
  uppercase?: boolean;
}

const CommentField: FC<CommentFieldProps> = ({
  className,
  value,
  name,
  disabled = false,
  defaultValue = 'Type...',
  onBlur,
  onChange,
  onFocus,
  uppercase = false,
}) => {
  const [focused, setFocused] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textAreaEl = useRef(null);
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    // update textarea height
    window.setTimeout(() => {
      setTextAreaHeight('auto');
      setTextAreaHeight(`${textAreaEl.current.scrollHeight}px`);
    }, 0);
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    onBlur && onBlur(e);
    setFocused(false);
  };

  const inputClass = [
    uppercase && value && styles.uppercase,
    disabled && styles.disabled,
    className,
  ].join(' ');

  return (
    <div className={styles.wrapper}>
      <textarea
        maxLength={1000}
        rows={1}
        value={value}
        name={name}
        placeholder={
          disabled ? t('noCommentsAllowed.label') : String(defaultValue)
        }
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={textAreaEl}
        style={{ height: textAreaHeight }}
        className={inputClass}
        disabled={disabled}
      />
      <div
        style={{
          fontFamily: '"Cabin Condensed", Helvetica, Arial, sans-serif',
          fontSize: '0.875rem',
        }}
        className={styles.charCount}>
        {value.toString.length} / 1000 {t('characters.label')}
      </div>
    </div>
  );
};

export default CommentField;
