// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Button-module-button-b_aG7 {
  position: relative;
  padding: 16px;
  font-family: "Barlow";
  color: #000;
  font-size: 17px;
  font-style: normal;
  line-height: 24px;
  background-color: #FFD700;
  border-radius: 16px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: none;
  font-weight: bold;
  position: relative;
}
.-Button-module-button-b_aG7:hover, .-Button-module-button-b_aG7:active, .-Button-module-button-b_aG7:focus {
  background-color: #F1CE36;
  border: none;
}
.-Button-module-button-b_aG7.-Button-module-largerButton-NWxkE {
  height: 62px;
}
.-Button-module-button-b_aG7.-Button-module-nexaFont-Izvau {
  font-size: 1rem;
  font-family: "nexa";
  font-weight: 700;
}
.-Button-module-button-b_aG7.-Button-module-secondary-sMAVa {
  background-color: transparent;
  color: #eee;
  font-weight: bold;
  text-decoration: underline;
}
.-Button-module-button-b_aG7.-Button-module-disabled-HULEw, .-Button-module-button-b_aG7.-Button-module-disabled-HULEw:hover {
  color: #B3B3B3;
  background: #191919;
}
.-Button-module-button-b_aG7.-Button-module-fullWidth-aAQf2 {
  width: 100%;
}
.-Button-module-button-b_aG7.-Button-module-gutterTop-Ay6Is {
  margin-top: 12px;
}
.-Button-module-button-b_aG7.-Button-module-center-xlAst {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.-Button-module-button-b_aG7.-Button-module-inlineButtons-j0koH {
  margin: 2px !important;
  padding: 2px 5px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 300px) {
  .-Button-module-button-b_aG7.-Button-module-inlineButtons-j0koH {
    height: auto;
    max-height: 50px;
    margin: 5px auto;
    width: 100%;
    min-width: 0;
    white-space: normal;
  }
}
.-Button-module-button-b_aG7.-Button-module-textButton-pNfEt {
  background: none;
  border: none;
  color: #eee;
  text-decoration: underline;
}
.-Button-module-button-b_aG7.-Button-module-textButton-pNfEt.-Button-module-disabled-HULEw, .-Button-module-button-b_aG7.-Button-module-textButton-pNfEt.-Button-module-disabled-HULEw:hover {
  color: #B3B3B3;
  background: none;
}
.-Button-module-button-b_aG7.-Button-module-slim-Pt9_d {
  padding: 8px;
}

.-Button-module-loader-mOIzu {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid rgba(0, 0, 0, 0);
  border-right-color: rgba(255, 165, 0, 0.5921568627);
  position: relative;
  animation: -Button-module-spinner-dI4zW 1s infinite linear;
  position: absolute;
}
.-Button-module-loader-mOIzu::before, .-Button-module-loader-mOIzu::after {
  content: "";
  position: absolute;
  inset: -8px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.-Button-module-loader-mOIzu::after {
  animation-duration: 4s;
}

@keyframes -Button-module-spinner-dI4zW {
  100% {
    transform: rotate(1turn);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,aCCO;EDAP,qBAAA;EACA,WE8CY;EF7CZ,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,yBE6BiB;EF5BjB,mBAAA;EAEA,aCRO;EDSP,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAHF;AAKE;EAGE,yBEgBoB;EFfpB,YAAA;AALJ;AAQE;EACE,YAAA;AANJ;AASE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAPJ;AAUE;EACE,6BAAA;EACA,WEUW;EFTX,iBAAA;EACA,0BAAA;AARJ;AAWE;EAEE,cEIa;EFHb,mBENe;AFJnB;AAaE;EACE,WAAA;AAXJ;AAcE;EACE,gBAAA;AAZJ;AAeE;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AAbJ;AAgBE;EACE,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAdJ;AAiBI;EARF;IASI,YAAA;IACA,gBAAA;IACA,gBAAA;IACA,WAAA;IACA,YAAA;IACA,mBAAA;EAdJ;AACF;AAiBE;EACE,gBAAA;EACA,YAAA;EACA,WEpCW;EFqCX,0BAAA;AAfJ;AAiBI;EAEE,cExCW;EFyCX,gBAAA;AAhBN;AAoBE;EACE,YAAA;AAlBJ;;AAsBA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,kCAAA;EACA,mDAAA;EACA,kBAAA;EACA,0DAAA;EACA,kBAAA;AAnBF;AAqBE;EAEE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AApBJ;AAuBE;EACE,sBAAA;AArBJ;;AAyBA;EACE;IACE,wBAAA;EAtBF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `-Button-module-button-b_aG7`,
	"largerButton": `-Button-module-largerButton-NWxkE`,
	"nexaFont": `-Button-module-nexaFont-Izvau`,
	"secondary": `-Button-module-secondary-sMAVa`,
	"disabled": `-Button-module-disabled-HULEw`,
	"fullWidth": `-Button-module-fullWidth-aAQf2`,
	"gutterTop": `-Button-module-gutterTop-Ay6Is`,
	"center": `-Button-module-center-xlAst`,
	"inlineButtons": `-Button-module-inlineButtons-j0koH`,
	"textButton": `-Button-module-textButton-pNfEt`,
	"slim": `-Button-module-slim-Pt9_d`,
	"loader": `-Button-module-loader-mOIzu`,
	"spinner": `-Button-module-spinner-dI4zW`
};
export default ___CSS_LOADER_EXPORT___;
