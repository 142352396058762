// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-DetailTitle-modules-wrapper-cNcu6 {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #eee;
  direction: ltr;
}
.-DetailTitle-modules-wrapper-cNcu6 .-DetailTitle-modules-back-Z4RNx {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.-DetailTitle-modules-wrapper-cNcu6 .-DetailTitle-modules-back-Z4RNx :first-child {
  margin-right: 8px;
}
.-DetailTitle-modules-wrapper-cNcu6 .-DetailTitle-modules-title-QQOuD {
  flex: 1;
  margin: 0;
  padding: 0px;
  font-size: 1em;
  color: #78909c;
  text-align: center;
}

.-DetailTitle-modules-backButton-jzOdK {
  color: #eee;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailTitle/DetailTitle.modules.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,aCDO;EDEP,WE2Ca;EF1Cb,cAAA;AAHF;AAKE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;EACE,iBCZQ;ADSd;AASE;EACE,OAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EACA,cEVO;EFWP,kBAAA;AAPJ;;AAWA;EACE,WEiBa;EFhBb,mBAAA;AARF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-DetailTitle-modules-wrapper-cNcu6`,
	"back": `-DetailTitle-modules-back-Z4RNx`,
	"title": `-DetailTitle-modules-title-QQOuD`,
	"backButton": `-DetailTitle-modules-backButton-jzOdK`
};
export default ___CSS_LOADER_EXPORT___;
