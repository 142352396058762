// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-HideShow-module-wrapper-mXNFA {
  width: 100%;
  max-height: 0;
  transition: all ease 500ms;
  overflow: hidden;
}
.-HideShow-module-wrapper-mXNFA.-HideShow-module-show-LXow6 {
  max-height: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/components/HideShow/HideShow.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,0BAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;AAEJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-HideShow-module-wrapper-mXNFA`,
	"show": `-HideShow-module-show-LXow6`
};
export default ___CSS_LOADER_EXPORT___;
