// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-PostDetail-module-wrapper-gHMWH {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  overflow-wrap: break-word;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or {
  direction: ltr;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-avatar-cSUyQ {
  margin-right: 16px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-logo-Ug61w {
  width: 84px;
  margin-right: 16px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-logo-Ug61w img {
  display: block;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-titles-PY3pf {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-titles-PY3pf .-PostDetail-module-title-Ty74O {
  color: #eee;
  font-weight: bold;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-titles-PY3pf .-PostDetail-module-subtitle-cdTwS {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #B3B3B3;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-meta-ke8Or .-PostDetail-module-close-MRAR2 {
  color: #eee;
  padding: 0;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf {
  flex: 1;
  padding: 16px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 512px;
  margin: 0 auto;
  width: 100%;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-image-mHXKr {
  width: 100%;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-opportunityDetails-OgKMK {
  background-color: #222;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 100%;
  color: #eee;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-opportunityDetails-OgKMK .-PostDetail-module-details-YlUc1 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 16px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-opportunityDetails-OgKMK .-PostDetail-module-label-u9zT_ {
  font-style: normal;
  font-weight: 700;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS {
  direction: ltr;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-avatar-cSUyQ {
  margin-right: 16px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-contentMeta-Qvvcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-contentMeta-Qvvcs .-PostDetail-module-boardMeta-Cdrnp {
  flex: 1;
  font-weight: 800;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-content-cgbUu {
  flex: 1;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-content-cgbUu p {
  margin: 0;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-content-cgbUu a {
  color: #FFD700;
  text-decoration: underline;
  font-weight: 700;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-icon-RdMEO {
  padding: 0;
  color: #ffffff;
  margin-left: 4px;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-contentWrapper-DkSuS .-PostDetail-module-icon-RdMEO.-PostDetail-module-liked-9rjsF svg {
  color: #E54D2E;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-translatedMessage-Aqukl,
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-translationError-RASP5 {
  direction: rtl;
  color: #B3B3B3;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-detail-NggPf .-PostDetail-module-body-o6dE7 .-PostDetail-module-translationError-RASP5 {
  color: #FF977D;
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-actions-Rv6uY {
  padding-bottom: calc(env(safe-area-inset-bottom) - 8px);
  padding-bottom: calc(constant(safe-area-inset-bottom) - 8px);
}
.-PostDetail-module-wrapper-gHMWH .-PostDetail-module-actions-Rv6uY .-PostDetail-module-comment-IPHL8 {
  display: flex;
  flex: 1;
}

.-PostDetail-module-flagOpen-N93_7 {
  margin-bottom: -12px;
}`, "",{"version":3,"sources":["webpack://./src/components/PostDetail/PostDetail.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,qCAAA;EACA,2CAAA;EACA,yBAAA;AAFF;AAIE;EACE,cAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAII;EACE,kBCfG;ADaT;AAKI;EACE,WAAA;EACA,kBAAA;AAHN;AAKM;EACE,cAAA;AAHR;AAOI;EACE,aAAA;EACA,sBAAA;EACA,OAAA;EACA,gBAAA;AALN;AAOM;EACE,WEWO;EFVP,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AALR;AAQM;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AANR;AAUI;EACE,WENS;EFOT,UAAA;AARN;AAYE;EACE,OAAA;EACA,aAAA;EACA,gBAAA;EACA,iCAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;AAVJ;AAYI;EACE,WAAA;AAVN;AAaI;EACE,cEjEE;EFkEF,aAAA;EACA,sBAAA;EACA,SAAA;AAXN;AAaM;EACE,sBExCS;EFyCT,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,WEtCO;AF2Bf;AAYQ;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AAVV;AAaQ;EACE,kBAAA;EACA,gBAAA;AAXV;AAeM;EACE,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAbR;AAeQ;EACE,kBCxGD;AD2FT;AAgBQ;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAdV;AAgBU;EACE,OAAA;EACA,gBAAA;AAdZ;AAmBQ;EACE,OAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAjBV;AAmBU;EACE,SAAA;AAjBZ;AAoBU;EACE,cElGO;EFmGP,0BAAA;EACA,gBAAA;AAlBZ;AAsBQ;EACE,UAAA;EACA,cEvIF;EFwIE,gBAAA;AApBV;AAuBY;EACE,cEhGI;AF2ElB;AA4BM;;EAEE,cAAA;EACA,cE7GS;AFmFjB;AA6BM;EACE,cE/GK;AFoFb;AAgCE;EACE,uDAAA;EACA,4DAAA;AA9BJ;AAgCI;EACE,aAAA;EACA,OAAA;AA9BN;;AAmCA;EACE,oBAAA;AAhCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-PostDetail-module-wrapper-gHMWH`,
	"meta": `-PostDetail-module-meta-ke8Or`,
	"avatar": `-PostDetail-module-avatar-cSUyQ`,
	"logo": `-PostDetail-module-logo-Ug61w`,
	"titles": `-PostDetail-module-titles-PY3pf`,
	"title": `-PostDetail-module-title-Ty74O`,
	"subtitle": `-PostDetail-module-subtitle-cdTwS`,
	"close": `-PostDetail-module-close-MRAR2`,
	"detail": `-PostDetail-module-detail-NggPf`,
	"image": `-PostDetail-module-image-mHXKr`,
	"body": `-PostDetail-module-body-o6dE7`,
	"opportunityDetails": `-PostDetail-module-opportunityDetails-OgKMK`,
	"details": `-PostDetail-module-details-YlUc1`,
	"label": `-PostDetail-module-label-u9zT_`,
	"contentWrapper": `-PostDetail-module-contentWrapper-DkSuS`,
	"contentMeta": `-PostDetail-module-contentMeta-Qvvcs`,
	"boardMeta": `-PostDetail-module-boardMeta-Cdrnp`,
	"content": `-PostDetail-module-content-cgbUu`,
	"icon": `-PostDetail-module-icon-RdMEO`,
	"liked": `-PostDetail-module-liked-9rjsF`,
	"translatedMessage": `-PostDetail-module-translatedMessage-Aqukl`,
	"translationError": `-PostDetail-module-translationError-RASP5`,
	"actions": `-PostDetail-module-actions-Rv6uY`,
	"comment": `-PostDetail-module-comment-IPHL8`,
	"flagOpen": `-PostDetail-module-flagOpen-N93_7`
};
export default ___CSS_LOADER_EXPORT___;
