// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-SelectField-module-wrapper-HCNJy {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.-SelectField-module-select-Uc26u .invictus__menu {
  border-radius: 12px;
  border: 1px #606060 solid;
  overflow: hidden;
  padding: 0;
  background: #191919;
  z-index: 8;
}
.-SelectField-module-select-Uc26u .invictus__menu .invictus__option {
  padding: 16px;
  font-size: 17px;
  font-style: normal;
  line-height: 24px;
  background: #191919;
  color: #B3B3B3;
  font-weight: normal;
}
.-SelectField-module-select-Uc26u .invictus__menu .invictus__option:nth-child(2n) {
  background: #222;
}
.-SelectField-module-select-Uc26u .invictus__menu .invictus__option.invictus__option--is-focused, .-SelectField-module-select-Uc26u .invictus__menu .invictus__option:hover, .-SelectField-module-select-Uc26u .invictus__menu .invictus__option:active, .-SelectField-module-select-Uc26u .invictus__menu .invictus__option:focus {
  color: #eee;
}
.-SelectField-module-select-Uc26u.-SelectField-module-error-wJedT .invictus__control {
  border-color: #FF977D;
}
.-SelectField-module-select-Uc26u .invictus__control {
  border-radius: 12px;
  border: 1px solid #606060;
  background: #2A2A2A;
  display: flex;
  min-height: 56px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #B3B3B3;
  box-shadow: none;
}
.-SelectField-module-select-Uc26u .invictus__control:hover, .-SelectField-module-select-Uc26u .invictus__control:active, .-SelectField-module-select-Uc26u .invictus__control:focus {
  outline: none;
  border: 1px solid #606060;
  color: #eee;
  box-shadow: none;
}
.-SelectField-module-select-Uc26u .invictus__control:focus-within {
  background: #313131;
  outline: none;
  border: 1px solid #606060;
  color: #eee;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__dropdown-indicator,
.-SelectField-module-select-Uc26u .invictus__control .invictus__placeholder,
.-SelectField-module-select-Uc26u .invictus__control .invictus__value-container {
  padding: 0;
  margin: 0;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__multi-value {
  background: #313131;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 18px;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__multi-value .invictus__multi-value__label {
  color: #eee;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__multi-value .invictus__multi-value__remove:hover {
  background: #313131;
  color: #FF977D;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__indicator,
.-SelectField-module-select-Uc26u .invictus__control .invictus__single-value {
  color: #B3B3B3;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__indicator:hover, .-SelectField-module-select-Uc26u .invictus__control .invictus__indicator:active, .-SelectField-module-select-Uc26u .invictus__control .invictus__indicator:focus,
.-SelectField-module-select-Uc26u .invictus__control .invictus__single-value:hover,
.-SelectField-module-select-Uc26u .invictus__control .invictus__single-value:active,
.-SelectField-module-select-Uc26u .invictus__control .invictus__single-value:focus {
  color: #eee;
}
.-SelectField-module-select-Uc26u .invictus__control .invictus__indicator-separator {
  display: none;
}
.-SelectField-module-select-Uc26u .invictus__control.invictus__control--menu-is-open .invictus__indicator {
  transform: rotate(180deg);
}
.-SelectField-module-select-Uc26u .invictus__input-container {
  color: #B3B3B3;
}
.-SelectField-module-select-Uc26u ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #B3B3B3;
  font-size: 17px;
}
.-SelectField-module-select-Uc26u ::-moz-placeholder {
  /* Firefox 19+ */
  color: #B3B3B3;
  font-size: 17px;
}
.-SelectField-module-select-Uc26u :-ms-input-placeholder {
  /* IE 10+ */
  color: #B3B3B3;
  font-size: 17px;
}
.-SelectField-module-select-Uc26u :-moz-placeholder {
  /* Firefox 18- */
  color: #B3B3B3;
  font-size: 17px;
}

.-SelectField-module-labelError-RbDUF {
  color: #f1321e;
}

.-SelectField-module-fieldWrapper-s8Q9g {
  position: relative;
}

.-SelectField-module-label-HmWhQ {
  position: relative;
  text-align: left;
  pointer-events: none;
  transition: all 0.15s ease;
  font-weight: 400;
  line-height: 18px;
  font-size: 1.25em;
}

.-SelectField-module-selectButton-ZM50o {
  min-height: 34px;
  padding: 10px 0px;
  top: 0px;
  position: relative;
  box-sizing: border-box;
  resize: none;
  font-size: 1em;
  background-color: transparent;
  width: 100%;
  transition: height 0.25s;
  outline: none;
}

.-SelectField-module-selectButton-ZM50o::first-letter {
  text-transform: capitalize;
}

.-SelectField-module-selectButtonValue-CnHJ5 {
  color: #000000;
}

.-SelectField-module-wrapperError-ZzmBF > .-SelectField-module-selectButton-ZM50o {
  color: #f1321e;
}

.-SelectField-module-selectWrapper-ufsjN > select {
  flex: 1;
  width: 100%;
  height: 54px;
  opacity: 0;
}

.-SelectField-module-selectWrapper-ufsjN {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.-SelectField-module-clearButton-C6nu1 {
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
}

.-SelectField-module-option-v6wi3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.-SelectField-module-option-v6wi3 img {
  width: 32px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectField/SelectField.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,gBCQW;ADVb;;AAOE;EACE,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,UAAA;EACA,mBE2Be;EF1Bf,UAAA;AAJJ;AAMI;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBEmBa;EFlBb,cE2BW;EF1BX,mBAAA;AAJN;AAMM;EACE,gBEaS;AFjBjB;AAOM;EAIE,WEeO;AFvBf;AAcI;EACE,qBEWO;AFvBb;AAgBE;EACE,mBAAA;EACA,yBAAA;EACA,mBENc;EFOd,aAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,cELa;EFMb,gBAAA;AAdJ;AAgBI;EAGE,aAAA;EACA,yBAAA;EACA,WEdS;EFeT,gBAAA;AAhBN;AAmBI;EACE,mBEzBU;EF0BV,aAAA;EACA,yBAAA;EACA,WEtBS;AFKf;AAoBI;;;EAGE,UAAA;EACA,SAAA;AAlBN;AAoBI;EACE,mBEtCU;EFuCV,yBAAA;EACA,kBAAA;EACA,eAAA;AAlBN;AAmBM;EACE,WErCO;AFoBf;AAoBQ;EACE,mBE/CM;EFgDN,cEvCG;AFqBb;AAsBI;;EAEE,cE/CW;AF2BjB;AAsBM;;;;EAGE,WErDO;AFkCf;AAuBI;EACE,aAAA;AArBN;AAyBM;EACE,yBAAA;AAvBR;AA4BE;EACE,cEpEa;AF0CjB;AA6BE;EACE,wBAAA;EACA,cEzEa;EF0Eb,eAAA;AA3BJ;AA8BE;EACE,gBAAA;EACA,cE/Ea;EFgFb,eAAA;AA5BJ;AA+BE;EACE,WAAA;EACA,cErFa;EFsFb,eAAA;AA7BJ;AAgCE;EACE,gBAAA;EACA,cE3Fa;EF4Fb,eAAA;AA9BJ;;AAkCA;EACE,cEhJI;AFiHN;;AAkCA;EACE,kBAAA;AA/BF;;AAkCA;EACE,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,0BAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AA/BF;;AAkCA;EACE,gBAAA;EACA,iBAAA;EACA,QAAA;EACA,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,6BAAA;EACA,WAAA;EACA,wBAAA;EACA,aAAA;AA/BF;;AAkCA;EACE,0BAAA;AA/BF;;AAmCA;EACE,cEjLM;AFiJR;;AAmCA;EACE,cEzLI;AFyJN;;AAmCA;EACE,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAhCF;;AAmCA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;AAhCF;;AAmCA;EACE,YC3MY;ED4MZ,WC5MY;ED6MZ,kBAAA;EACA,iBC9MY;AD8Kd;;AAmCA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,QAAA;AAhCF;AAkCE;EACE,WAAA;EACA,YAAA;AAhCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-SelectField-module-wrapper-HCNJy`,
	"select": `-SelectField-module-select-Uc26u`,
	"error": `-SelectField-module-error-wJedT`,
	"labelError": `-SelectField-module-labelError-RbDUF`,
	"fieldWrapper": `-SelectField-module-fieldWrapper-s8Q9g`,
	"label": `-SelectField-module-label-HmWhQ`,
	"selectButton": `-SelectField-module-selectButton-ZM50o`,
	"selectButtonValue": `-SelectField-module-selectButtonValue-CnHJ5`,
	"wrapperError": `-SelectField-module-wrapperError-ZzmBF`,
	"selectWrapper": `-SelectField-module-selectWrapper-ufsjN`,
	"clearButton": `-SelectField-module-clearButton-C6nu1`,
	"option": `-SelectField-module-option-v6wi3`
};
export default ___CSS_LOADER_EXPORT___;
