// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Modal-module-backdrop-OmOx_ {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 16px;
  z-index: 250;
  display: flex;
  align-items: center;
  justify-content: center;
}
.-Modal-module-backdrop-OmOx_ .-Modal-module-modal-ufBCd {
  width: 100%;
  max-width: 350px;
  margin-top: 500px;
  transition: all ease 500ms;
  opacity: 0;
}
.-Modal-module-backdrop-OmOx_.-Modal-module-open-XiWqm .-Modal-module-modal-ufBCd {
  margin-top: 0;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,qCAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;EACA,UAAA;AACJ;AAEI;EACE,aAAA;EACA,UAAA;AAAN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `-Modal-module-backdrop-OmOx_`,
	"modal": `-Modal-module-modal-ufBCd`,
	"open": `-Modal-module-open-XiWqm`
};
export default ___CSS_LOADER_EXPORT___;
