// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Comment-module-wrapper-DbRdC {
  display: flex;
  flex-direction: row;
  margin: 16px 0px;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-avatar-Q_K20 {
  height: 48px;
  width: 48px;
  margin-right: 8px;
  overflow: hidden;
  background-color: #546e7a;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 100%;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-comment-ermW9 {
  padding: 8px 12px 12px;
  border-radius: 12px;
  background-color: #222;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-comment-ermW9 .-Comment-module-name-BLBX5 {
  color: #eee;
  flex: 1;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 8px;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-comment-ermW9 .-Comment-module-extendLineHeight-G_6st {
  line-height: 25px;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-comment-ermW9 .-Comment-module-content-Cq5VV {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #B3B3B3;
  margin: 0;
  gap: 8px;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-metaWrapper-ipM3Q {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-metaWrapper-ipM3Q .-Comment-module-created-aZQwL,
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-metaWrapper-ipM3Q .-Comment-module-translatedMessage-PFzPN,
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-metaWrapper-ipM3Q .-Comment-module-translationError-JhsDv {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #B3B3B3;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-metaWrapper-ipM3Q .-Comment-module-translationError-JhsDv {
  color: #f1321e;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-mention-japO9 {
  background-color: #78909c;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.-Comment-module-wrapper-DbRdC .-Comment-module-body-cMhkj .-Comment-module-mention-japO9 .-Comment-module-comment-ermW9 .-Comment-module-content-Cq5VV {
  margin-block-start: 5px !important;
}
.-Comment-module-wrapper-DbRdC.-Comment-module-blocked-wgt1I .-Comment-module-avatar-Q_K20 {
  opacity: 0.5;
}
.-Comment-module-wrapper-DbRdC.-Comment-module-blocked-wgt1I .-Comment-module-body-cMhkj {
  color: #b0bec5;
}
.-Comment-module-wrapper-DbRdC.-Comment-module-blocked-wgt1I .-Comment-module-body-cMhkj .-Comment-module-created-aZQwL {
  color: #b0bec5;
}

.-Comment-module-flagOpen-I4v1x {
  margin-bottom: -12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Comment/Comment.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAKE;EACE,YAAA;EACA,WAAA;EACA,iBCPU;EDQV,gBAAA;EACA,yBEMO;AFTX;AAME;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;AAJJ;AAMI;EACE,sBAAA;EACA,mBAAA;EACA,sBEeW;AFnBjB;AAMM;EACE,WEqBO;EFpBP,OAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAJR;AAOM;EACE,iBAAA;AALR;AAQM;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cEIS;EFHT,SAAA;EAEA,QAAA;AAPR;AAWI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AATN;AAWM;;;EAGE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cEfS;AFMjB;AAYM;EACE,cElEF;AFwDN;AAcI;EACE,yBE1DK;EF2DL,cElEE;EFmEF,gBAAA;EACA,kBAAA;EACA,eAAA;AAZN;AAeQ;EACE,kCAAA;AAbV;AAoBI;EACE,YAAA;AAlBN;AAqBI;EACE,cEhFK;AF6DX;AAqBM;EACE,cEnFG;AFgEX;;AAyBA;EACE,oBAAA;AAtBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Comment-module-wrapper-DbRdC`,
	"avatar": `-Comment-module-avatar-Q_K20`,
	"body": `-Comment-module-body-cMhkj`,
	"comment": `-Comment-module-comment-ermW9`,
	"name": `-Comment-module-name-BLBX5`,
	"extendLineHeight": `-Comment-module-extendLineHeight-G_6st`,
	"content": `-Comment-module-content-Cq5VV`,
	"metaWrapper": `-Comment-module-metaWrapper-ipM3Q`,
	"created": `-Comment-module-created-aZQwL`,
	"translatedMessage": `-Comment-module-translatedMessage-PFzPN`,
	"translationError": `-Comment-module-translationError-JhsDv`,
	"mention": `-Comment-module-mention-japO9`,
	"blocked": `-Comment-module-blocked-wgt1I`,
	"flagOpen": `-Comment-module-flagOpen-I4v1x`
};
export default ___CSS_LOADER_EXPORT___;
