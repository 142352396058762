import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './TextFieldSlim.module.scss';
import { useTranslation } from 'react-i18next';

export interface TextFieldSlimProps {
  className?: string;
  value: string;
  name: string;
  disabled?: boolean;
  label: string;
  multiLine?: boolean;
  errorText?: string;
  uppercase?: boolean;
  maxLength?: number;
  min?: string;
  max?: string;
  inputClass?: string;
  inputRef?: Ref<HTMLInputElement>;
  touched?: boolean;
  type?: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
}

const TextFieldSlim: FC<TextFieldSlimProps> = ({
  className,
  value,
  name,
  disabled = false,
  touched = false,
  errorText,
  multiLine = false,
  onBlur,
  onChange,
  onFocus,
  inputRef,
  label,
  uppercase = false,
  type = 'text',
  maxLength = 524288,
  min,
  max,
}) => {
  const [focused, setFocused] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textAreaEl = useRef(null);
  const { t } = useTranslation();

  const updateHeight = () => {
    if (multiLine) {
      window.setTimeout(() => {
        setTextAreaHeight('auto');
        setTextAreaHeight(`${textAreaEl.current.scrollHeight}px`);
      }, 0);
    }
  };

  useEffect(() => {
    updateHeight();
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(e);
    updateHeight();
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onBlur) {
      onBlur(e);
    }
    setFocused(false);
  };

  const inputClass = [
    uppercase && value && styles.uppercase,
    disabled && styles.disabled,
    className,
  ].join(' ');

  const fieldType = () => {
    if (multiLine) {
      return (
        <>
          <textarea
            maxLength={1000}
            rows={1}
            value={value}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={textAreaEl}
            className={inputClass}
            style={{ height: textAreaHeight }}
            disabled={disabled}
          />
          <div
            style={{
              fontFamily: '"Cabin Condensed", Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
            }}>
            {value?.length} / 1000 {t('characters.label')}
          </div>
        </>
      );
    }
    return (
      <input
        value={value}
        name={name}
        type={type && type}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        maxLength={maxLength}
        className={inputClass}
        disabled={disabled}
        min={min}
        max={max}
      />
    );
  };

  const wrapperClasses = [
    styles.wrapper,
    value && styles.wrapperValue,
    focused && styles.wrapperActive,
    disabled && styles.wrapperDisabled,
    errorText && styles.wrapperError,
  ].join(' ');

  return (
    <div className={wrapperClasses}>
      <div className={styles.label}>{label}</div>
      {fieldType()}
      {errorText && touched && (
        <div className={styles.errorText}>{errorText}</div>
      )}
    </div>
  );
};

export default TextFieldSlim;
