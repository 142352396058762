// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-RemovePostListItem-module-icon-CvIdR {
  transition: color ease 0.25s;
}
.-RemovePostListItem-module-icon-CvIdR.-RemovePostListItem-module-saving-ayEM8 {
  color: #ff9800;
}

.-RemovePostListItem-module-decisionCopy-Ku_Ny {
  padding: 0px 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/RemovePostListItem/RemovePostListItem.module.scss"],"names":[],"mappings":"AAIA;EACE,4BAAA;AAHF;AAKE;EACE,cAAA;AAHJ;;AAOA;EACE,iBAAA;AAJF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `-RemovePostListItem-module-icon-CvIdR`,
	"saving": `-RemovePostListItem-module-saving-ayEM8`,
	"decisionCopy": `-RemovePostListItem-module-decisionCopy-Ku_Ny`
};
export default ___CSS_LOADER_EXPORT___;
