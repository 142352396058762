// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatDisclaimer-module-label-hHtoR {
  font-weight: 400;
  font-weight: bold;
}

.-ChatDisclaimer-module-bodyText-KUL9n {
  font-weight: 200;
  padding: 12px 0;
  color: #eee;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatDisclaimer/ChatDisclaimer.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,WCyCa;AD1Cf","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `-ChatDisclaimer-module-label-hHtoR`,
	"bodyText": `-ChatDisclaimer-module-bodyText-KUL9n`
};
export default ___CSS_LOADER_EXPORT___;
