// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-SavePostListItem-module-icon-d1log {
  transition: color ease 0.25s;
}
.-SavePostListItem-module-icon-d1log.-SavePostListItem-module-saving-HGB9Z {
  color: #ff9800;
}`, "",{"version":3,"sources":["webpack://./src/components/SavePostListItem/SavePostListItem.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA;EACE,4BAAA;AAFF;AAIE;EACE,cCCS;ADHb","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `-SavePostListItem-module-icon-d1log`,
	"saving": `-SavePostListItem-module-saving-HGB9Z`
};
export default ___CSS_LOADER_EXPORT___;
