// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Img-module-placeholder-CB0Z9 {
  position: relative;
  width: 100%;
  background: linear-gradient(270deg, #cfd8dc, #eceff1);
  background-size: 400% 400%;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: -Img-module-gradientLoad-RrpwD;
  animation-iteration-count: infinite;
}
.-Img-module-placeholder-CB0Z9.-Img-module-loaded-z6dxh {
  background: transparent;
}
.-Img-module-placeholder-CB0Z9 img {
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.25;
  transition: opacity 0.25s ease;
}
.-Img-module-placeholder-CB0Z9 img.-Img-module-loaded-z6dxh {
  opacity: 1;
}

@keyframes -Img-module-gradientLoad-RrpwD {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Img/Img.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,qDAAA;EACA,0BAAA;EACA,8BAAA;EACA,sBAAA;EACA,iCAAA;EACA,yBAAA;EACA,8CAAA;EACA,mCAAA;AADF;AAGE;EACE,uBAAA;AADJ;AAIE;EACE,cAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,8BAAA;AAFJ;AAGI;EACE,UAAA;AADN;;AAMA;EACE;IAAG,2BAAA;EAFH;EAGA;IAAI,6BAAA;EAAJ;EACA;IAAK,2BAAA;EAEL;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `-Img-module-placeholder-CB0Z9`,
	"gradientLoad": `-Img-module-gradientLoad-RrpwD`,
	"loaded": `-Img-module-loaded-z6dxh`
};
export default ___CSS_LOADER_EXPORT___;
