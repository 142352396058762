// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-DropZoneField-module-trigger-Yyecz {
  width: 100%;
  outline: none;
}

.-DropZoneField-module-fieldWrapper-JIqW0 {
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}

.-DropZoneField-module-removeThumb-SFGH7 {
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E54D2E;
  z-index: 2;
}
.-DropZoneField-module-removeThumb-SFGH7 svg {
  width: 16px;
  height: 16px;
}

.-DropZoneField-module-fileThumb-qkT7H {
  position: relative;
  color: #eee;
  font-size: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191919;
  border: 1px dashed #484848;
  background-size: cover;
  background-position: center center;
  border-radius: 12px;
  padding: 54px 10px;
  width: 100%;
  flex-direction: column;
}
.-DropZoneField-module-fileThumb-qkT7H.-DropZoneField-module-thumbnail-KQPXs {
  height: 132px;
  border: 1px solid #191919;
}
.-DropZoneField-module-fileThumb-qkT7H.-DropZoneField-module-thumbnail-KQPXs .-DropZoneField-module-selectedDocument-YlVzp {
  display: none;
}
.-DropZoneField-module-fileThumb-qkT7H .-DropZoneField-module-uploadIcon-i8N_Y {
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: #eee;
  display: flex;
  align-items: center;
  gap: 2px;
}
.-DropZoneField-module-fileThumb-qkT7H .-DropZoneField-module-maxUploadSize-gJEeu {
  color: #B3B3B3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
}
.-DropZoneField-module-fileThumb-qkT7H .-DropZoneField-module-maxUploadSize-gJEeu span {
  font-weight: bold;
}
.-DropZoneField-module-fileThumb-qkT7H.-DropZoneField-module-fileSelected-C68Rk {
  background: none;
  border: none;
  padding: 0;
  justify-content: space-between;
}
.-DropZoneField-module-fileThumb-qkT7H .-DropZoneField-module-input-dbACY {
  visibility: hidden;
  width: 100px;
  height: 100px;
  position: absolute;
}
.-DropZoneField-module-fileThumb-qkT7H .-DropZoneField-module-playIcon-VYGcJ {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
}

.-DropZoneField-module-videoThumb-MtPNH {
  width: 100%;
  height: auto;
}

.-DropZoneField-module-label-wdQwa {
  color: #B3B3B3;
  font-family: "Nexa";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.-DropZoneField-module-selectedDocument-YlVzp {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #B3B3B3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.-DropZoneField-module-selectedDocument-YlVzp .-DropZoneField-module-clearText-P6FZn a {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  color: #FF977D;
}

.-DropZoneField-module-statusBlock-TvNKn {
  margin: 0;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/DropZoneField/DropZoneField.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,aAAA;AAHF;;AAKA;EACE,aAAA;EACA,QAAA;EACA,sBAAA;EACA,WAAA;AAFF;;AAKA;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBC8BgB;ED7BhB,UAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,WCea;EDdb,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBCCiB;EDAjB,0BAAA;EACA,sBAAA;EACA,kCAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;AAHF;AAIE;EACE,aAAA;EACA,yBAAA;AAFJ;AAGI;EACE,aAAA;AADN;AAKE;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WCZW;EDaX,aAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAKE;EACE,cCjBa;EDkBb,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAHJ;AAII;EACE,iBAAA;AAFN;AAME;EACE,gBAAA;EACA,YAAA;EACA,UAAA;EACA,8BAAA;AAJJ;AAOE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AALJ;AAOE;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AALJ;;AASA;EACE,WAAA;EACA,YAAA;AANF;;AAQA;EACE,cCvDe;EDwDf,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AALF;;AAQA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCrEe;EDsEf,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AALF;AAOI;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,+BAAA;EACA,cC/EO;AD0Eb;;AAUA;EACE,SAAA;EACA,kBAAA;AAPF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `-DropZoneField-module-trigger-Yyecz`,
	"fieldWrapper": `-DropZoneField-module-fieldWrapper-JIqW0`,
	"removeThumb": `-DropZoneField-module-removeThumb-SFGH7`,
	"fileThumb": `-DropZoneField-module-fileThumb-qkT7H`,
	"thumbnail": `-DropZoneField-module-thumbnail-KQPXs`,
	"selectedDocument": `-DropZoneField-module-selectedDocument-YlVzp`,
	"uploadIcon": `-DropZoneField-module-uploadIcon-i8N_Y`,
	"maxUploadSize": `-DropZoneField-module-maxUploadSize-gJEeu`,
	"fileSelected": `-DropZoneField-module-fileSelected-C68Rk`,
	"input": `-DropZoneField-module-input-dbACY`,
	"playIcon": `-DropZoneField-module-playIcon-VYGcJ`,
	"videoThumb": `-DropZoneField-module-videoThumb-MtPNH`,
	"label": `-DropZoneField-module-label-wdQwa`,
	"clearText": `-DropZoneField-module-clearText-P6FZn`,
	"statusBlock": `-DropZoneField-module-statusBlock-TvNKn`
};
export default ___CSS_LOADER_EXPORT___;
